<template>
  <div>
    <div class="input-group add-on">
      <input class="form-control"
             type="text"
             placeholder="Search"
             autocomplete="off"
             v-model="filterText"
             @keyup.enter="doFilter">
      <div class="input-group-append">
        <button class="btn btn-primary" type="submit" @click="doFilter">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <button class="btn btn-outline-primary ml-2" @click="resetFilter">
        <i class="fas fa-sync-alt"></i>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name  : 'FeaturedFilterBar',
    props : {
      list : String,
    },
    data() {
      return {
        filterText : '',
      }
    },
    methods : {

      /**
       * Filter Action
       */
      doFilter() {
        if (this.list === 'featured')
          this.$events.fire('filter-set-featured', this.filterText);
        else
          this.$events.fire('filter-set-album', this.filterText);
      },

      /**
       * Reset Filter
       */
      resetFilter() {
        this.filterText = '';  // clear the text in text input
        if (this.list === 'featured')
          this.$events.fire('filter-reset-featured');
        else
          this.$events.fire('filter-reset-album');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/featured_album/filter-bar";
</style>

